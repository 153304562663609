import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Projects`}</h3>
    <hr></hr>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/chrischenyc/fuutr-server"
        }}>{`FUUTR`}</a>{` (2019, dead 😵): Pre-seed startup, Lime-like scooter-sharing platform.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/chrischenyc/clickstyle-web"
        }}>{`ClickStyle`}</a>{` (2017-2018, dead 😵): Pre-seed startup, two-sided platform for freelance stylists and beauticians.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.workoutsnap.com"
        }}>{`WorkoutSnap`}</a>{` (2016, live 😃): fitness photo editing iOS app. DAU 1.4K and MAU 8.8K at its peak, now in auto-pilot.`}</li>
    </ul>
    <h3>{`Certifications`}</h3>
    <hr></hr>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.credly.com/badges/c73098b8-f99c-4741-a8ea-4c19f2216fa4"
        }}>{`AWS Certified Cloud Practitioner`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youracclaim.com/badges/4e300bf2-ea23-456c-aa70-82e5215fe581"
        }}>{`AWS Certified Solution Architect - Associate`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.credly.com/badges/dd3c830a-8f18-40bb-89c3-df11899dad5f"
        }}>{`AWS Certified Developer - Associate`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.credly.com/badges/174a059c-272c-41bf-8a83-acd16f98ce8e"
        }}>{`AWS Certified SysOps Admin - Associate`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.credly.com/badges/4e300bf2-ea23-456c-aa70-82e5215fe581"
        }}>{`AWS Certified Security - Speciality`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.credly.com/badges/8e928852-560b-4563-b4cf-f2d07d395055"
        }}>{`AWS DevOps Engineer - Professional`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      